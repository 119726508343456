<template>
    <div v-show="show">
        <div class="fixed inset-0 z-10" />
        <div
            class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
            <div class="flex items-center justify-between">
                <Link :href="route('home')" class="-m-1.5 p-1.5" @click="emit('setOpen', false)">
                    <span class="sr-only">Mimovo</span>
                    <ApplicationLogo class="h-9" />
                </Link>
                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="emit('setOpen', false)">
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="mt-6 flow-root">
                <div class="-my-6 divide-y divide-gray-500/10">
                    <div class="space-y-2 py-6">
                        <Link
                            v-for="item in navItems"
                            :key="item.name"
                            :href="item.href"
                            @click="emit('setOpen', false)"
                            :class="{ 'border-t border-gray-200 py-6': item.name.toLowerCase() === 'log in' }"
                            class="-mx-3 block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                            {{ item.name }}</Link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import { Link } from '@inertiajs/vue3'

defineProps({
    navItems: {
        type: Array,
        required: true,
    },
    show: {
        type: Boolean,
        required: true,
    },
})

const emit = defineEmits(['setOpen'])
</script>
